<template>
  <section class="screens_parent_app">
    <div class="container">
      <h4 class="title">{{ $t("some_screen_parent_app") }}</h4>
      <p class="text">{{ $t("screen_parent_app_text") }}</p>
    </div>
    <div class="screens">
      <swiper
        :centeredSlides="true"
        :spaceBetween="30"
        :loop="true"
        :loopFillGroupWithBlank="true"
        :modules="modules"
        :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 5,
            slidesPerGroup: 1,
            loopFillGroupWithBlank: true,
          },
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(screen, index) in screens" :key="index">
          <img :src="screen.image" alt="screen_image" />
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper";

export default {
  name: "Screens-parent",
  data() {
    return {
      screens: [
        {
          id: "1",
          image: require("@/assets/media/parent_app1.png"),
        },
        {
          id: "2",
          image: require("@/assets/media/parent_app2.png"),
        },
        {
          id: "3",
          image: require("@/assets/media/parent_app3.png"),
        },
        {
          id: "4",
          image: require("@/assets/media/parent_app4.png"),
        },
        {
          id: "5",
          image: require("@/assets/media/parent_app5.png"),
        },
        {
          id: "6",
          image: require("@/assets/media/parent_app1.png"),
        },
        {
          id: "7",
          image: require("@/assets/media/parent_app2.png"),
        },
        {
          id: "8",
          image: require("@/assets/media/parent_app3.png"),
        },
        {
          id: "9",
          image: require("@/assets/media/parent_app4.png"),
        },
        {
          id: "10",
          image: require("@/assets/media/parent_app5.png"),
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style></style>

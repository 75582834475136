// aboutService.js
import axios from 'axios';

let aboutData = null;

export default {
    async fetchAboutData() {
        if (!aboutData) {
            const { data } = await axios.get("/fetch_about");
            aboutData = data.data;
        }
        return aboutData;
    }
}

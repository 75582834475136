// applicationService.js
import axios from 'axios';

let applicationData = null;

export default {
    async fetchApplicationData() {
        if (!applicationData) {
            const { data } = await axios.get("/fetch_download_app_section");
            applicationData = data.data;
        }
        return applicationData;
    }
}

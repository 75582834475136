<template>
  <lottie-player
    src="https://lottie.host/a1e149d0-db09-405b-907d-3d6b8af76515/XrxcmwTptk.json"
    background="#FFFFFF0"
    speed="1"
    loop
    autoplay
    direction="1"
    mode="normal"
  ></lottie-player>
   <!-- <lottie-player
    src="https://lottie.host/a0fa08b5-42bf-47c8-9a59-5d66ccc5bcb1/zv8zpDvfh0.json"
    background="#FFFFFF0"
    speed="1"
    loop
    autoplay
    direction="1"
    mode="normal"
  ></lottie-player> -->
</template>

<script>
export default {
    name: "loading-lottie"
};
</script>

<style scoped>
    lottie-player {
        width: 500px;
        height: 500px;
    }
</style>

// courseService.js
import axios from 'axios';

let courseData = null;

export default {
    async fetchCourseData() {
        if (!courseData) {
            const { data } = await axios.get("/fetch_home_courses");
            courseData = data.data;
        }
        return courseData;
    }
}

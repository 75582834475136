<script>
export default {
  name: "videoIcon",
  props: {
    color: String
  }
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M13 3.25H7C3.58 3.25 2.25 4.58 2.25 8V16C2.25 18.3 3.5 20.75 7 20.75H13C16.42 20.75 17.75 19.42 17.75 16V8C17.75 4.58 16.42 3.25 13 3.25Z" :fill="color"/>
    <path d="M11.4991 11.3811C12.5374 11.3811 13.3791 10.5394 13.3791 9.50109C13.3791 8.4628 12.5374 7.62109 11.4991 7.62109C10.4608 7.62109 9.61914 8.4628 9.61914 9.50109C9.61914 10.5394 10.4608 11.3811 11.4991 11.3811Z" :fill="color"/>
    <path d="M21.6505 6.17157C21.2405 5.96157 20.3805 5.72157 19.2105 6.54157L17.7305 7.58157C17.7405 7.72157 17.7505 7.85157 17.7505 8.00157V16.0016C17.7505 16.1516 17.7305 16.2816 17.7305 16.4216L19.2105 17.4616C19.8305 17.9016 20.3705 18.0416 20.8005 18.0416C21.1705 18.0416 21.4605 17.9416 21.6505 17.8416C22.0605 17.6316 22.7505 17.0616 22.7505 15.6316V8.38157C22.7505 6.95157 22.0605 6.38157 21.6505 6.17157Z" :fill="color"/>
  </svg>
</template>

<style scoped>

</style>
<template>
    <div class="col-lg-3 col-md-12 col-12 sidebar_full_screen">
        <div class="sidebar">
<!--            <h5 class="title">{{ $t("continue_content") }}</h5>-->
            <Sessions :sessions="sessions" v-if="sessions.length" @session-clicked="handleSessionClicked" />
            <Exams :exams="exams" v-if="exams.length" />
        </div>
    </div>
</template>

<script>
import Sessions from '@/components/include/lesson/sessions.vue';
import Exams from '@/components/include/course/exams.vue';

export default {
    name: "sidebar-component",
    components: {
        Sessions,
        Exams
    },
    props: {
        exams: Array,
        sessions: Array
    },
    methods: {
        handleSessionClicked(session) {
            // Handle the session click event, e.g., display detailed information
            this.$emit("session-clicked", session);
        },
    }
}
</script>

<style>

</style>
<template>
    <examComponent />
</template>

<script>
import examComponent from '@/components/include/exams/examComponent.vue'

export default {
    name: "exam-page",
    components: {
        examComponent
    },
}
</script>

<style>

</style>
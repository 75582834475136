import axios from "axios";
export default {
  async Register(context, Form) {
    let response = await axios.post("/register", Form);
    localStorage.setItem("token", response.data.data.api_token);
    const UserResource = {
      user_id: response.data.data.id,
      name: response.data.data.name,
      email: response.data.data.email,
      phone: response.data.data.phone,
      device_name: response.data.data.device_name,
      user_info: response.data.data.user_info,
      type: response.data.data.type,
    };
    localStorage.setItem("user",  JSON.stringify(UserResource));
    return response;
  },
  async Login({ commit }, Form) {
    try {
      let response = await axios.post("/login", Form);
      localStorage.setItem("token", response.data.data.api_token);
      const UserResource = {
        user_id: response.data.data.id, 
        name: response.data.data.name,
        email: response.data.data.email,
        phone: response.data.data.phone,
        device_name: response.data.data.device_name,
        user_info: response.data.data.user_info
      };
      localStorage.setItem("user",  JSON.stringify(UserResource));
      commit("setUser", { user: response });

      return response;
    } catch (error) {
      return error;
    }
  },

  logout({ commit }) {
    commit("logout");
  },
};

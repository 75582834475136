<template>
  <div class="col-lg-9 col-md-8 col-12">
    <section class="courses courses_page">
      <div class="container">
        <div class="row">
          <courseCard :courses="courses" />
        </div>
        <div class="row">
          <pagination
            :data="courses"
            class="mx-auto d-flex align-items-center justify-content-center pagination"
            @pagination-change-page="fetchMyCourses"
          >
            <template v-slot:prev-nav>&lt;</template>
            <template v-slot:next-nav>&gt;</template>
          </pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import courseCard from "@/components/include/cards/courseCard.vue";
import pagination from "laravel-vue-pagination";
// import Loading from "@/components/include/LottieFiles/loading.vue";

export default {
  name: "courses-page",
  data() {
    return {
      courses: {},
      loading: true,
    };
  },
  components: {
    courseCard,
    pagination,
    // Loading,
  },
  methods: {
    fetchMyCourses(page = 1) {
      axios
        .get(`/fetch_my_courses?page=${page}`)
        .then(({ data }) => {
          this.loading = true;
          this.courses = data.data;
          // console.log(this.header);
        })
        .catch((error) => {
          return error;
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.fetchMyCourses();
  },
};
</script>
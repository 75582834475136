<template>
  <section class="exam">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <p class="title_section">{{$t("exams")}}</p>
          <h4 class="title">{{exam.title}} </h4>
          <p class="text">{{exam.sub_title}}</p>
          <ul class="features">
            <li class="feature" v-for="(feature, index) in exam.features" :key="index">{{feature.title}}</li>
          </ul>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="exam.image" class="img_exam" alt="exam">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import examService from '@/services/examService';

export default {
  name: "exam-home",
  data(){
    return {
      exam: {}
    }
  },
  methods: {
    async fetchExam() {
      this.exam = await examService.fetchExamData();
    },
  },
  created(){
    this.fetchExam();
  }
}
</script>

<style>
/* Add your styles here */
</style>

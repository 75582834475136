// middleService.js
import axios from 'axios';

let middleData = null;

export default {
    async fetchMiddleData() {
        if (!middleData) {
            const { data } = await axios.get("/fetch_middle_section");
            middleData = data.data;
        }
        return middleData;
    }
}

<template>
    <section class="profile">
        <div class="container">
            <div class="row">
                <sidebar />
                <Answer />
            </div>
        </div>
    </section>
</template>

<script>
import sidebar from '@/components/include/profile/sidebar.vue'
import Answer from '@/components/include/profile/answer.vue';

export default {
    name: "answer-profile",
    components: {
        sidebar,
        Answer
    }
}
</script>

<style>

</style>
<script>
export default {
  name: "lessonIcon",
  props: {
    color: String
  },
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M21.6602 10.4385L20.6802 14.6185C19.8402 18.2285 18.1802 19.6885 15.0602 19.3885C14.5602 19.3485 14.0202 19.2585 13.4402 19.1185L11.7602 18.7185C7.59018 17.7285 6.30018 15.6685 7.28018 11.4885L8.26018 7.29854C8.46018 6.44854 8.70018 5.70854 9.00018 5.09854C10.1702 2.67854 12.1602 2.02854 15.5002 2.81854L17.1702 3.20854C21.3602 4.18854 22.6402 6.25854 21.6602 10.4385Z" :fill="color"/>
    <path d="M15.0603 19.3916C14.4403 19.8116 13.6603 20.1616 12.7103 20.4716L11.1303 20.9916C7.16034 22.2716 5.07034 21.2016 3.78034 17.2316L2.50034 13.2816C1.22034 9.31156 2.28034 7.21156 6.25034 5.93156L7.83034 5.41156C8.24034 5.28156 8.63034 5.17156 9.00034 5.10156C8.70034 5.71156 8.46034 6.45156 8.26034 7.30156L7.28034 11.4916C6.30034 15.6716 7.59034 17.7316 11.7603 18.7216L13.4403 19.1216C14.0203 19.2616 14.5603 19.3516 15.0603 19.3916Z" :fill="color"/>
    <path d="M17.4894 10.5117C17.4294 10.5117 17.3694 10.5017 17.2994 10.4917L12.4494 9.26173C12.0494 9.16173 11.8094 8.75173 11.9094 8.35173C12.0094 7.95173 12.4194 7.71173 12.8194 7.81173L17.6694 9.04173C18.0694 9.14173 18.3094 9.55173 18.2094 9.95173C18.1294 10.2817 17.8194 10.5117 17.4894 10.5117Z" :fill="color"/>
    <path d="M14.5592 13.8889C14.4992 13.8889 14.4392 13.8789 14.3692 13.8689L11.4592 13.1289C11.0592 13.0289 10.8192 12.6189 10.9192 12.2189C11.0192 11.8189 11.4292 11.5789 11.8292 11.6789L14.7392 12.4189C15.1392 12.5189 15.3792 12.9289 15.2792 13.3289C15.1992 13.6689 14.8992 13.8889 14.5592 13.8889Z" :fill="color"/>
  </svg>
</template>

<style scoped>

</style>
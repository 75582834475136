// partnerService.js
import axios from 'axios';

let partnerData = null;

export default {
    async fetchPartnerData() {
        if (!partnerData) {
            const { data } = await axios.get("/fetch_home_lecturers");
            partnerData = data.data;
        }
        return partnerData;
    }
}

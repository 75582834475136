<template>
    <div class="col-lg-3 col-md-4 col-12">
        <div class="sidebar">
            <div class="personal_data">
                <!-- <img src="@/assets/media/7w7w.jpg" class="user_image" alt="user"> -->
                <div class="data">
                    <h6 class="name">{{user?.name}}</h6>
                    <p class="phone">{{user?.phone}}</p>
                    <p class="email">{{user?.email}}</p>
                </div>
            </div>
            <ul class="side_links">
                <li>
                    <router-link to="/my_courses">
                        <i class="bi bi-play-circle"></i>
                        <p>{{ $t("my_courses") }}</p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/exams">
                        <i class="bi bi-card-checklist"></i>
                        <p>{{ $t("my_exams") }}</p>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/history">
                        <i class="bi bi-file-earmark-arrow-up"></i>
                        <p>{{ $t("views_history") }}</p>
                    </router-link>
                </li>
                <li>
                    <router-link to="/">
                        <i class="bi bi-bell"></i>
                        <p>{{ $t("notifications") }}</p>
                    </router-link>
                </li> -->
                <!-- <li>
                    <router-link to="/">
                        <i class="bi bi-person-gear"></i>
                        <p>{{ $t("profile_setting") }}</p>
                    </router-link>
                </li> -->
                <li class="logout">
                    <button class="btn" @click="removeToken">
                        <i class="bi bi-box-arrow-right"></i>
                        <p>{{ $t("logout") }}</p>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    name: "sidebar-profile",
    data() {
        return {
            user: JSON.parse(localStorage.getItem("user"))
        }
    },
    methods: {
        async removeToken() {
            Swal.fire({
                title: this.$t("check_logout"),
                text: this.$t("are_you_sure_logout"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: this.$t("back"),
                confirmButtonText: this.$t("confirm_logout"),
            }).then((result) => {
                if (result.isConfirmed) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                location.reload();
                }
            });
        },
    }
}
</script>
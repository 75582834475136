<template>
    <div class="lessons">
        <h5 class="title">{{ $t("lessons") }} ({{ lessons?.length }})</h5>
        <ul class="lessons_card">
          <li class="lesson_card" v-for="(lesson, index) in lessons" :key="index">
                <router-link class="lesson_check" :to="`/lesson/${lesson.id}`" v-if="lesson.available">
                    <p class="name">
                        <LessonIcon :color="PrimaryColor" />
                        {{ lesson.name }}
                    </p>
                    <span class="description" v-if="lesson.description">{{ lesson.description.substring(0, 150) }}</span>
<!--                    <div class="time">-->
<!--                        <i class="fa-regular fa-clock"></i>-->
<!--                        {{ lesson.duration }} {{ $t("minute") }}-->
<!--                    </div>-->
                </router-link>
                <div class="lesson_check disabled" v-tooltip.top="'هذه الحصه غير متاحه لك'" v-else>
                    <div class="d-flex">
                      <p class="name">
                        <LessonIcon :color="PrimaryColor" />
                        {{ lesson.name }}
                      </p>
                      <span class="description">{{ lesson.description.substring(0, 150) }}</span>
                    </div>
                    <div class="lock">
                        <LockIcon :color="PrimaryColor" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import LessonIcon from '@/assets/icons/lessonIcon.vue';
import LockIcon from '@/assets/icons/lockIcon.vue';

export default {
    name: "lessons-card",
    data() {
        return {
            PrimaryColor: JSON.parse(localStorage.getItem("web_status"))?.primary_color,
        }
    },
    props: {
        lessons: Array,
    },
    components: {
      LessonIcon,
      LockIcon
    }
}
</script>
<template>
  <div class="partners">
    <div class="container">
      <p class="title_section">{{$t("success_partners")}}</p>
      <h4 class="title">{{partner.title}}</h4>
      <p class="text">{{partner.sub_title}}</p>
      <swiper
          :spaceBetween="30"
          :slidesPerGroup="1"
          :loop="true"
          :loopFillGroupWithBlank="true"
          :navigation="true"
          :modules="modules"
          :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
          :breakpoints="{
          300: {
            slidesPerView: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 2,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: partner.lecturers.length === 1 ? 1 : 3,
            loopFillGroupWithBlank: true,
          },
        }"
          class="mySwiper"
      >
        <swiper-slide v-for="(lecturer, index) in partner.lecturers" :key="index">
          <router-link :to="`/courses/teacher/${lecturer.id}`" class="card_partners">
            <img :src="lecturer.image" alt="partners_image">
            <h5 class="name">{{lecturer.name}}</h5>
            <p class="job_title">{{lecturer.education}}</p>
            <p class="faculty">{{lecturer.description}}</p>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/navigation";
import partnerService from '@/services/partnerService';

export default {
  name: "Partners-home",
  data() {
    return {
      partner: {
        lecturers: []
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
  methods: {
    async fetchPartners() {
      this.partner = await partnerService.fetchPartnerData();
    },
  },
  created() {
    this.fetchPartners();
  },
};
</script>

<style>
/* Add your styles here */
</style>

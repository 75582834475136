<template>
  <section class="statistics" v-if="statistics.length">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-6" v-for="(statistic, index) in statistics" :key="index">
          <div class="card_statistic">
            <h4 class="title">{{ statistic.title }}</h4>
            <p class="text">{{ statistic.sub_title }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import statisticsService from '@/services/statisticsService';

export default {
  name: "statistics-home",
  data() {
    return {
      statistics: []
    }
  },
  methods: {
    async fetchStatistics() {
      this.statistics = await statisticsService.fetchStatisticsData();
    },
  },
  created() {
    this.fetchStatistics();
  }
}
</script>

<style>
/* Add your styles here */
</style>

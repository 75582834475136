<template>
  <section class="breadcrumb">
    <div class="container">
      <h2 class="title">{{ $t("courses") }}</h2>
    </div>
  </section>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="courses py-2 courses_page">
    <div class="container">
      <div class="row" v-if="levels.length">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-group mb-3">
            <label for="levels">{{ $t("levels") }}</label>
            <select class="form-control" v-model="level_id" @change="fetchCourses">
              <option v-for="(level, index) in levels" :key="index" :value="level.id">{{ level.title }}</option>
            </select>
          </div>
        </div>
      </div>
      <courseCard :courses="courses.data" />
      <div class="row">
        <pagination
          :data="courses"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchCourses"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import courseCard from "@/components/include/cards/courseCard.vue";
import pagination from "laravel-vue-pagination";
import Loading from "@/components/include/LottieFiles/loading.vue";

export default {
  name: "courses-page",
  data() {
    return {
      courses: {},
      levels: [],
      loading: true,
      teacher_id: this.$route.params.teacher_id,
      user_id: JSON.parse(localStorage.getItem("user"))?.user_id,
      auth: this.$store.getters['isAuthenticated'],
    };
  },
  components: {
    courseCard,
    pagination,
    Loading,
  },
  methods: {
    fetchCourses(page = 1) {
      if(this.teacher_id) {
        const formData = new FormData();
        formData.append("lecturer_id", this.teacher_id);
        if(this.level_id) formData.append("level_id", this.level_id);
        if(this.auth) {
          if(this.user_id) formData.append("user_id", this.user_id);
        }
        axios
            .post(`/fetch_teacher_courses?page=${page}`, formData)
            .then(({ data }) => {
              this.loading = true;
              this.courses = data.data;
              // console.log(this.header);
            })
            .catch((error) => {
              return error;
              // this.$router.push({ path: "/teachers" });
            })
            .finally(() => (this.loading = false));
      }
      else {
        axios
            .get(`/fetch_courses?page=${page}`)
            .then(({ data }) => {
              this.loading = true;
              this.courses = data.data;
              // console.log(this.header);
            })
            .catch((error) => {
              return error;
              // this.$router.push({ path: "/teachers" });
            })
            .finally(() => (this.loading = false));
      }
    },

    fetchLevels() {
      const formData = new FormData();
      formData.append("lecturer_id", this.teacher_id);
      axios
        .post(`/fetch_levels`, formData)
        .then(({data}) => {
          this.loading = true;
          this.levels = data.data;
        })
        .catch((error) => {
          return error;
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
    }
  },
  created() {
    this.fetchCourses();
    this.fetchLevels()
  },
};
</script>

<template>
    <Features />
    <App />
    <Screens />
</template>

<script>
import Features from '@/components/include/parent/features.vue'
import App from '@/components/include/parent/app.vue'
import Screens from '@/components/include/parent/screens.vue'

export default {
    name: "parent-page",
    components: {
        Features,
        App,
        Screens
    }
}
</script>

<style>

</style>
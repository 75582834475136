<template>
  <Header />
  <Statistics />
  <About />
  <Partners />
  <Cources />
  <middleSection />
  <Opinions />
  <Exam />
  <!-- <Parent /> -->
  <Faq />
  <Application />
</template>

<script>
import Header from '@/components/include/index/header.vue'
import Statistics from '@/components/include/index/statistics.vue'
import About from '@/components/include/index/about.vue'
import Partners from '@/components/include/index/partners.vue'
import Cources from '@/components/include/index/courses.vue'
import middleSection from '@/components/include/index/middleSection.vue'
import Opinions from '@/components/include/index/opinions.vue'
import Exam from '@/components/include/index/exam.vue'
// import Parent from '@/components/include/index/parent.vue'
import Faq from '@/components/include/index/faq.vue'
import Application from '@/components/include/index/application.vue'

export default {
    name: "home-page",
    components: {
      Header,
      Statistics,
      About,
      Partners,
      Cources,
      middleSection,
      Opinions,
      Exam,
      // Parent,
      Faq,
      Application
    }
}
</script>

<style>

</style>
<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="history">
        <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t("login_date") }}</th>
                  <th scope="col">{{ $t("logout_date") }}</th>
                  <th scope="col">{{ $t("period_spent") }}</th>
                  <th scope="col">{{ $t("period_viewing") }}</th>
                  <th scope="col">{{ $t("browser") }}</th>
                  <th scope="col">{{ $t("device_name") }}</th>
                  <th scope="col">{{ $t("device_type") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="history in histories" :key="history.id">
                  <td :data-label="$t('login_date')">{{ history.login_date }}</td>
                  <td :data-label="$t('logout_date')">{{ history.logout_date }}</td>
                  <td :data-label="$t('period_spent')">{{ history.period_spent }}</td>
                  <td :data-label="$t('period_viewing')">{{ history.period_viewing }}</td>
                  <td :data-label="$t('browser')">{{ history.browser }}</td>
                  <td :data-label="$t('device_name')">{{ history.device_name }}</td>
                  <td :data-label="$t('device_type')">{{ history.device_type }}</td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "history-profile",
  data() {
    return {
      histories: [
        {
            id: 1,
            login_date: "2011/04/25",
            logout_date: "2011/04/30",
            period_spent: "30",
            period_viewing: "20",
            browser: "Chrome",
            device_name: "Poco F3",
            device_type: "Mobile"
        },
        {
            id: 2,
            login_date: "2011/04/25",
            logout_date: "2011/04/30",
            period_spent: "30",
            period_viewing: "20",
            browser: "Chrome 119",
            device_name: "Unknown",
            device_type: "Desktop"
        }
      ]
    }
  }
};
</script>

<style></style>

<template>
    <section class="profile">
        <div class="container">
            <div class="row">
                <sidebar />
                <History />
            </div>
        </div>
    </section>
</template>

<script>
import sidebar from '@/components/include/profile/sidebar.vue';
import History from '@/components/include/profile/history.vue';

export default {
    name: "history-profile",
    components: {
        sidebar,
        History
    }
}
</script>

<style>

</style>
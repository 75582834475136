<template>
  <section class="courses" id="courses" v-if="showHomeCourses">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <p class="title_section">{{$t("courses")}}</p>
        <router-link class="btn show_more" to="/courses">{{$t("show_more")}} > </router-link>
      </div>
      <h4 class="title">{{course.title}}</h4>
      <p class="text">{{course.sub_title}}</p>

      <courseCard :courses="course.courses" />
    </div>
  </section>
</template>

<script>
import courseService from '@/services/courseService';
import courseCard from "../../include/cards/courseCard.vue";

export default {
  name: "courses-home",
  data(){
    return {
      course: {},
      showHomeCourses: JSON.parse(localStorage.getItem("web_status"))?.show_home_courses,
    }
  },
  components: {
    courseCard
  },
  methods: {
    async fetchCourses() {
      this.course = await courseService.fetchCourseData();
    },
  },
  created(){
    this.fetchCourses();
  }
}
</script>

<style>
/* Add your styles here */
</style>

<template>
    <div class="exams">
        <h5 class="title">{{ $t("exams") }} ({{ exams?.length }})</h5>
        <ul class="exams_card">
            <li class="exam_card" v-for="(exam, index) in exams" :key="index">
                <router-link class="exam_check" :to="`${routeName == 'coursesDetailsPage' ? '/exam/'+exam.id : (routeName == 'lessonExam' || routeName == 'lesson') ? '/lesson/'+lessonID+'/exam/'+exam.id : ''}`" v-if="exam.available">
                    <p class="name">
                        <i class="fa-solid fa-list-check"></i>
                        {{ exam.name }}
                    </p>
                    <div class="time">
                        <i class="fa-regular fa-clock"></i>
                        {{ exam.duration }} {{ $t("minute") }}
                    </div>
                </router-link>
                <div class="exam_check disabled" v-tooltip.top="'هذا الامتحان غير متاح لك'" v-else>
                    <p class="name">
                        <i class="fa-solid fa-list-check"></i>
                        {{ exam.name }}
                    </p>
                    <div class="time">
                        <i class="fa-regular fa-clock"></i>
                        {{ exam.duration }} {{ $t("minute") }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "exams-card",
    data() {
        return {
            routeName: this.$route.name,
            lessonID: this.$route.params.id     
        }
    },
    props: {
        exams: Array
    },
    created() {
        // console.log(this.routeName)
    }
}
</script>
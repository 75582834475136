<template>
    <section class="profile">
        <div class="container">
            <div class="row">
                <sidebar />
                <MyCourses />
            </div>
        </div>
    </section>
</template>

<script>
import sidebar from '@/components/include/profile/sidebar.vue';
import MyCourses from '@/components/include/profile/courses.vue';

export default {
    name: "courses-profile",
    components: {
        sidebar,
        MyCourses
    }
}
</script>

<style>

</style>
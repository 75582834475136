<template>
  <section class="application" id="download">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12 d-flex flex-column justify-content-center">
          <p class="title_section">{{$t("download_application")}}</p>
          <h2 class="title">{{application.title}}</h2>
          <p class="text">{{application.sub_title}}</p>
          <div class="buttons">
            <a :href="application.android" class="link" target="_blank">
              <img src="../../../assets/media/google_white.png" alt="google">
            </a>
            <a :href="application.ios" class="link" target="_blank">
              <img src="../../../assets/media/apple_white.png" alt="apple">
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <img :src="application.image" class="application_image" :alt="application.title">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import applicationService from '@/services/applicationService';

export default {
  name: "application-home",
  data() {
    return {
      application: {},
    };
  },
  methods: {
    async fetchApplication() {
      this.application = await applicationService.fetchApplicationData();
    },
  },
  created() {
    this.fetchApplication();
  },
};
</script>

<style>
/* Add your styles here */
</style>

<template>
  <div :class="isPiP ? 'video-player-pip' : 'video-player'">
    <Player
        theme="dark"
        id="myVideo"
        :style="`--vm-player-theme: ${primary_color}`"
        class="content"
    >
      <Youtube
          :showFullscreenControl="false"
          :videoId="videoId"
          @vmReady="onPlayerReady"
      />
      <Ui>
        <DefaultSettings />
        <Controls>
          <PlaybackControl />
          <VolumeControl />
          <ScrubberControl />
          <SettingsControl />
          <!--          <button @click="TogglePip" class="pip-button">PIP</button>-->
        </Controls>
        <Poster />
        <ClickToPlay />
        <LoadingScreen>
          <img :src="logo" class="image_loading" alt="logo" />
        </LoadingScreen>
      </Ui>
      <div class="tapSidesToSeek">
        <div class="spacer"></div>
        <div class="tapTarget"></div>
        <div class="spacer"></div>
      </div>
    </Player>
  </div>
</template>

<script>
import {
  Player,
  Youtube,
  Ui,
  LoadingScreen,
  Controls,
  PlaybackControl,
  VolumeControl,
  SettingsControl,
  ScrubberControl,
  Poster,
  DefaultSettings,
  ClickToPlay,
} from '@vime/vue-next';
import '@/assets/css/default.css';

export default {
  name: "youtube-video",
  props: {
    videoId: String,
  },
  data() {
    return {
      primary_color: JSON.parse(localStorage.getItem("web_status"))?.primary_color,
      logo: JSON.parse(localStorage.getItem("web_status"))?.logo,
      isPiP: false,
      playerInstance: null,
    };
  },
  components: {
    Player,
    Youtube,
    Ui,
    Controls,
    PlaybackControl,
    VolumeControl,
    SettingsControl,
    ScrubberControl,
    Poster,
    DefaultSettings,
    ClickToPlay,
    LoadingScreen,
  },
  methods: {
    TogglePip() {
      this.isPiP = !this.isPiP;
    },
    onPlayerReady(event) {
      // Access the YouTube player instance
      this.playerInstance = event.detail; // YouTube player instance
      this.setHDQuality();
    },
    setHDQuality() {
      if (this.playerInstance && this.playerInstance.setPlaybackQuality) {
        // Set the desired quality level, 'hd1080' for 1080p or 'hd720' for 720p
        this.playerInstance.setPlaybackQuality('hd1080');
      }
    }
  },
};
</script>

<style>
/* Add any styles here */
</style>

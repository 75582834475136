// headerService.js
import axios from 'axios';

let headerData = null;

export default {
    async fetchHeaderData() {
        if (!headerData) {
            const { data } = await axios.get("/fetch_header");
            headerData = data.data;
        }
        return headerData;
    }
}

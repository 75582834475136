// faqService.js
import axios from 'axios';

let faqData = null;

export default {
    async fetchFaqData(routeName) {
        if (!faqData) {
            const { data } = await axios.get("/fetch_home_common_questions");
            faqData = data.data;
        }
        return {
            common: faqData,
            questions: routeName === "faq" ? faqData.questions : faqData.questions.slice(0, 4),
        };
    }
}

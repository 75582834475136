<template>
  <section class="app">
        <h4 class="title">{{$t("You can now download the app for parents and take advantage of the distinctive features available within the application")}}</h4>
        <button class="btn PrimaryButton" data-bs-toggle="modal" data-bs-target="#downloadModal">{{$t("get_app")}}</button>
    </section>
</template>

<script>
export default {
    name: "app-name"
}
</script>

<style>

</style>
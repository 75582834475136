// statisticsService.js
import axios from 'axios';

let statisticsData = null;

export default {
    async fetchStatisticsData() {
        if (!statisticsData) {
            const { data } = await axios.get("/fetch_statistics");
            statisticsData = data.data;
        }
        return statisticsData;
    }
}

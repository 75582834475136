<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="exams">
      <div class="head">
        <div class="data">
          <h3 class="title">{{ $t("my_exams") }} / {{ $t("my_answers") }}</h3>
          <p class="text">{{ $t("answe_content_here") }}</p>
          <ul class="data_statistics">
            <li class="total">
              <i class="bi bi-card-checklist"></i>
              <p class="title">{{ $t("total_questions") }} :</p>
              <span class="text">{{answers.length}}</span>
            </li>
            <li class="success">
              <i class="bi bi-check-circle"></i>
              <p class="title">{{ $t("total_answer_success") }} :</p>
              <span class="text">{{correctCount}}</span>
            </li>
            <li class="wrong">
              <i class="bi bi-x-circle"></i>
              <p class="title">{{ $t("total_answer_wrong") }} :</p>
              <span class="text">{{wrongCount}}</span>
            </li>
          </ul>
        </div>
        <div class="statistics">
          <div class="card flex justify-content-center">
            <div class="chart">
              <Chart
                type="doughnut"
                :data="chartData"
                :options="chartOptions"
                class="w-full md:w-30rem"
              />
            </div>
            <div class="label_chart">
              <p class="title">{{ $t("total_questions") }}: {{ answers.length }}</p>
              <p class="title">{{ $t("total_answer_success") }}: {{ correctCount }}</p>
              <p class="title">{{ $t("total_answer_wrong") }}: {{ wrongCount }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aswers">
        <h2 class="title">{{ $t("answers") }}</h2>
        <div class="card_answer" v-for="(answer, index) in answers" :key="index">
            <p class="question_number">{{ $t("question") }} {{ index+1 }}</p>
            <h5 class="question">{{ answer.question }}</h5>
            <p class="student_answer" v-if="answer.is_correct == 0">{{ answer.your_answer }}</p>
            <p class="correct_answer">{{ answer.correct_answer }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import Chart from 'primevue/chart';
import axios from 'axios';

export default {
  name: "answer-profile",
  data() {
    return {
        chartData: null,
        chartOptions: {
            cutout: "60%",
        },
        color: [
            "#2D2D72",
            "#EDB83E",
            "#CBD3DC",
        ],
        answers: [],
        correctCount: "",
        wrongCount: ""
    }
  },
  components: {
    Chart
  },
  methods: {
    setChartData() {
      return {
        labels: [this.$t("total_answer_success"), this.$t("total_answer_wrong")],
        datasets: [
          {
            data: [this.correctCount, this.wrongCount],
            backgroundColor: this.color,
            hoverBackgroundColor: this.color
          },
        ],
      };
    },
    setChartOptions() {
      return {
        plugins: {
          legend: {
            display: false, // Set to false to hide the legend
          },
        },
      };
    },
    fetchResultAnswers() {
      const exam_result_id = {exam_result_id: this.$route.params.exam_id}
      axios
        .post(`/fetch_result_answers`, exam_result_id)
        .then(({ data }) => {
          this.loading = true;
          this.answers = data.data;
          // console.log(this.answers);

          // Count the number of questions where is_correct is equal to 1
          this.correctCount = data.data.reduce((count, question) => count + (question.is_correct === 1 ? 1 : 0), 0);

          // Count the number of questions where is_correct is equal to 0
          this.wrongCount = data.data.reduce((count, question) => count + (question.is_correct === 0 ? 1 : 0), 0);
          
          this.chartData = this.setChartData();
          this.chartOptions = this.setChartOptions();
        })
        .catch((error) => {
        return error;
        // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.fetchResultAnswers()
  },
};
</script>

<style></style>

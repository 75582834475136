// examService.js
import axios from 'axios';

let examData = null;

export default {
    async fetchExamData() {
        if (!examData) {
            const { data } = await axios.get("/fetch_exams_section");
            examData = data.data;
        }
        return examData;
    }
}

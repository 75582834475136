import { createRouter, createWebHistory } from "vue-router";
import store from '../src/store/index.js';

//import website
import indexPage from "../src/views/index.vue";
import LoginPage from "../src/views/auth/login.vue";
import RegisterPage from "../src/views/auth/register.vue";
import coursesPage from "../src/views/courses.vue";
import coursesTeacherPage from "../src/views/courses.vue";
import coursesDetailsPage from "../src/views/courses_details.vue";
import parentPage from "../src/views/parent.vue";
import faqPage from "../src/views/faq.vue";
// import NotFound from "./components/pages/error";

// Profile
import myCoursesPage from "../src/views/profile/my_courses.vue";
import examsPage from "../src/views/profile/exams.vue";
import historyPage from "../src/views/profile/history.vue";
import examsAnswerPage from "../src/views/profile/answer.vue";
import examPage from "../src/views/exam.vue";
import lessonPage from "../src/views/lesson/lesson.vue";
import lessonExamPage from "../src/views/lesson/exam.vue";


const routes = [
    // Website routes
    { path: "/", component: indexPage, name: "index" },
    { path: "/login", component: LoginPage, name: "login", meta: { guest: true } },
    { path: "/register", component: RegisterPage, name: "register", meta: { guest: true } },
    { path: "/courses", component: coursesPage, name: "courses" },
    { path: "/courses/teacher/:teacher_id", component: coursesTeacherPage, name: "coursesTeacher" },
    { path: "/course/:id", component: coursesDetailsPage, name: "coursesDetailsPage" },
    { path: "/parent", component: parentPage, name: "parent" },
    { path: "/faq", component: faqPage, name: "faq" },
    // { path: "/404", component: NotFound},
    // { path: "/:catchAll(.*)", redirect: "/404"},

    // Profile
    { path: "/my_courses", component: myCoursesPage, name: "myCoursesPage", meta: { requiresAuth: true }, },
    { path: "/exams", component: examsPage, name: "exams", meta: { requiresAuth: true }, },
    { path: "/exam/:exam_id", component: examPage, name: "exam", meta: { requiresAuth: true }, },
    { path: "/exam/answer/:exam_id", component: examsAnswerPage, name: "answer", meta: { requiresAuth: true }, },
    { path: "/lesson/:id", component: lessonPage, name: "lesson", meta: { requiresAuth: true }, },
    { path: "/lesson/:id/exam/:exam_id", component: lessonExamPage, name: "lessonExam", meta: { requiresAuth: true }, },
    { path: "/history", component: historyPage, name: "history", meta: { requiresAuth: true }, },
];

const router = createRouter({
    routes,
    history: createWebHistory(),
    hashbang: false,
    mode: "history",
    linkActiveClass: "active",
    // linkExactActiveClass: "exact-active",
    scrollBehavior() {
        // Scroll to the top of the page
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/exams");
            return;
        }
        next();
    } else {
        next();
    }
});  

export default router;
<script>
import faq from '@/components/include/index/faq.vue'
export default {
  name: "faq-page",
  components: {
    faq
  }
}
</script>

<template>
  <faq />
</template>

<style scoped>

</style>
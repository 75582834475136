<template>
    <section class="lessons">
        <div class="container">
            <div class="row">
                <sidebar :sessions="sessions" :exams="exams" @session-clicked="handleSessionClicked" />
                <lessons :session="session" />
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import sidebar from '@/components/include/lesson/sidebar.vue'
import lessons from '@/components/include/lesson/session_details.vue'

export default {
    name: "lesson-page",
    data() {
        return {
            sessions: [],
            exams: [],
            documents: [],
            loading: false,
            session: {},
            exam: {}
        }
    },
    components: {
        sidebar,
        lessons,
    },
    methods: {
        fetchLessonsDetails() {
            const lesson_id = {lesson_id: this.$route.params.id}
            axios
                .post(`/fetch_lesson_sessions_and_exams`, lesson_id)
                .then(({ data }) => {
                    this.loading = true;
                    this.sessions = data.data.sessions;
                    this.exams = data.data.exams;
                    this.documents = data.data.documents;
                    //   console.log(this.course);
                })
                .catch((error) => {
                    return error;
                    // this.$router.push({ path: "/teachers" });
                })
                .finally(() => (this.loading = false));
        },
        handleSessionClicked(session) {
            // Handle the session click event, e.g., display detailed information
            this.session = session
            // console.log(session)
        },
        handleExamClicked(exam) {
            // Handle the exam click event, e.g., display detailed information
            this.exam = exam
            // console.log(exam)
        }
    },
    mounted() {
        this.fetchLessonsDetails();
    }
}
</script>
<template>
  <div class="col-lg-9 col-md-8 col-12">
    <div class="exams" v-if="exams?.length">
      <!-- <div class="head">
        <div class="data">
          <h3 class="title">{{ $t("my_exams") }}</h3>
          <p class="text">{{ $t("exam_content_here") }}</p>
          <ul class="data_statistics">
            <li class="total">
              <i class="bi bi-card-checklist"></i>
              <p class="title">{{ $t("total_exams") }} :</p>
              <span class="text">20</span>
            </li>
            <li class="success">
              <i class="bi bi-check-circle"></i>
              <p class="title">{{ $t("total_answer_success") }} :</p>
              <span class="text">16</span>
            </li>
            <li class="wrong">
              <i class="bi bi-x-circle"></i>
              <p class="title">{{ $t("total_answer_wrong") }} :</p>
              <span class="text">4</span>
            </li>
          </ul>
        </div>
        <div class="statistics">
          <div class="card flex justify-content-center">
            <div class="chart">
              <Chart
                type="doughnut"
                :data="chartData"
                :options="chartOptions"
                class="w-full md:w-30rem"
              />
            </div>
            <div class="label_chart">
              <p class="title">اجمالى الامتحانات</p>
              <p class="title">الاجابات الصحيحة</p>
              <p class="title">الاجابات الخاطئة</p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="content_exam">
        <h4 class="title">{{ $t("all_exams") }}</h4>
        <!-- <select name="" id="" class="form-control">
          <option value="">english course</option>
          <option value="">Math course</option>
          <option value="">physics course</option>
        </select> -->
        <div class="card_exam" v-for="exam in exams" :key="exam.id">
          <div class="exam_data">
            <p class="title">
              <i class="bi bi-ui-checks"></i> {{ exam.name }}
            </p>
            <ul class="exam_data_numbers">
              <li>
                <i class="bi bi-clock"></i>
                <p class="title">{{ $t("exam_time") }} :</p>
                <span class="text">{{ exam.time }} {{ $t("minute") }}</span>
              </li>
              <li>
                <i class="bi bi-card-checklist"></i>
                <p class="title">{{ $t("total_answer") }} :</p>
                <span class="text">{{ exam.your_questions }}</span>
              </li>
            </ul>
            <div class="progress_data">
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{
                    width: `${
                      (exam.correct_answers / exam.total_questions) * 100
                    }%`,
                  }"
                ></div>
              </div>
              <div class="percentage">
                {{ (exam.correct_answers / exam.total_questions) * 100 }}%
              </div>
            </div>
          </div>
          <div class="result">
            <div class="my_result">
              {{ exam.total_questions }} / {{ exam.correct_answers }}
            </div>
            {{ $t("current_result") }}
          </div>
          <div class="actions">
            <!-- <button class="btn BlueButton">{{ $t("continue_exam") }}</button> -->
            <router-link :to="`/exam/answer/${exam.id}`" class="btn BlueSecondButton">
              {{ $t("show_result") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <img src="@/assets/media/empty.png" alt="empty">
      <p>{{ $t("empty_exam_text") }}</p>
    </div>
  </div>
</template>

<script>
// import Chart from 'primevue/chart';
import axios from 'axios';

export default {
  name: "exams-profile",
  data() {
    return {
      exams: [],
      chartData: null,
      chartOptions: {
        cutout: "60%",
      },
      color: [
        "#2D2D72",
        "#EDB83E",
        "#CBD3DC",
      ]
    };
  },
  // components: {
  //   Chart
  // },
  methods: {
    setChartData() {
      return {
        labels: ["اجمالى الامتحانات", "الاجابات الصحيحة", "الاجابات الخاطئة"],
        datasets: [
          {
            data: [540, 325, 702],
            backgroundColor: this.color,
            hoverBackgroundColor: this.color
          },
        ],
      };
    },
    setChartOptions() {
      return {
        plugins: {
          legend: {
            display: false, // Set to false to hide the legend
          },
        },
      };
    },
    fetchExamsAnswers() {
      axios
        .get(`/fetch_results`)
        .then(({ data }) => {
          this.loading = true;
          this.exams = data.data;
          // console.log(this.answers);
        })
        .catch((error) => {
          return error;
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
    this.fetchExamsAnswers()
  },
};
</script>

<style></style>

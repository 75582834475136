<template>
  <section class="parent">
        <div class="container">
            <h4 class="title">{{$t("Parents will continue to follow the levels of students and their activities step by step")}}</h4>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12 d-flex flex-column justify-content-around">
                    <div class="card_parent_system">
                        <i class="fa-solid fa-book-open"></i>
                        <h6 class="title">{{$t("Follow the courses to which the student")}}</h6>
                        <p class="text">{{$t("screen_parent_app_text")}}</p>
                    </div>
                    <div class="card_parent_system mx-auto">
                        <i class="fa-regular fa-thumbs-up"></i>
                        <h6 class="title">{{$t("Follow up to teachers' assessments first hand")}}</h6>
                        <p class="text">{{$t("screen_parent_app_text")}}</p>
                    </div>
                    <div class="card_parent_system">
                        <i class="fa-solid fa-list-check"></i>
                        <h6 class="title">{{$t("Follow up to the attendees and leaving the student")}}</h6>
                        <p class="text">{{$t("screen_parent_app_text")}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <img src="@/assets/media/parent_app.png" class="parent_app" alt="parent_app">
                </div>
                <div class="col-lg-4 col-md-6 col-12 d-flex flex-column justify-content-around">
                    <div class="card_parent_system">
                        <i class="fa-solid fa-clipboard-question"></i>
                        <h6 class="title">{{$t("Follow up the exams and their results first hand")}}</h6>
                        <p class="text">{{$t("screen_parent_app_text")}}</p>
                    </div>
                    <div class="card_parent_system mx-auto">
                        <i class="fa-solid fa-book"></i>
                        <h6 class="title">{{$t("Follow the duties schedule first hand")}}</h6>
                        <p class="text">{{$t("screen_parent_app_text")}}</p>
                    </div>
                    <div class="card_parent_system">
                        <i class="fa-regular fa-comments"></i>
                        <h6 class="title">{{$t("The ability to communicate with teachers")}}</h6>
                        <p class="text">{{$t("screen_parent_app_text")}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "features-parent"
}
</script>

<style>

</style>
<template>
  <section class="maintenance">
    <div class="container">
      <img
        src="../../../assets/media/changes.png"
        alt="changes"
      />
      <h2 class="title">{{ $t("We Are Making Some Changes") }}</h2>
      <h5 class="text">{{ $t("will be back soon") }}</h5>
    </div>
  </section>
</template>

<script>
export default {
  name: "some-changes",
};
</script>

<style></style>

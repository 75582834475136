<template>
  <section class="faq" id="faq">
    <div class="container">
      <p class="title_section">{{$t("faq")}}</p>
      <h4 class="title">{{common.title}}</h4>
      <p class="text">{{common.sub_title}}</p>
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-12" v-for="(question, index) in questions" :key="index">
            <div class="accordion-item">
              <h2 class="accordion-header" :id="`flush-heading${index}`">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#flush-collapseOne${index}`" aria-expanded="false" :aria-controls="`flush-collapseOne${index}`">
                  {{question.question}}
                </button>
              </h2>
              <div :id="`flush-collapseOne${index}`" class="accordion-collapse collapse" :aria-labelledby="`flush-heading${index}`" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">{{question.answer}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link to="/faq" class="d-block  btn mt-3 mx-auto PrimaryButton" v-if="routeName !== 'faq'">{{ $t("show_more") }}</router-link>
    </div>
  </section>
</template>

<script>
import faqService from '@/services/faqService';

export default {
  name: "faq-home",
  data(){
    return {
      common: {},
      questions: [],
      routeName: this.$route.name,
    }
  },
  methods: {
    async fetchFaq() {
      const faqData = await faqService.fetchFaqData(this.routeName);
      this.common = faqData.common;
      this.questions = faqData.questions;
    },
  },
  created(){
    this.fetchFaq();
  }
}
</script>

<style>
/* Add your styles here */
</style>

// opinionsService.js
import axios from 'axios';

let opinionsData = null;

export default {
    async fetchOpinionsData() {
        if (!opinionsData) {
            const { data } = await axios.get("/fetch_home_audience_opinions");
            opinionsData = data.data;
        }
        return opinionsData;
    }
}
